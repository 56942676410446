import React from "react";
import alpha from "../assets/img/icons/horace-cube.png";
import redx from "../assets/img/icons/cnp.png";
import pshadow from "../assets/img/perspective-shadow.png";
import pphone from "../assets/img/perspective-phone.png";
import Navi from "./Navi";
const Solution = () => {
  return (
    <>
      <Navi navType="dark" />
      <section id="solutions" className="features-2-section">
        <div className="container">
          <div className="section-header text-center">
            <h2 className="wow fadeInUp" data-wow-delay=".2s">
              Our Solutions
            </h2>
            <p className="wow fadeInUp" data-wow-delay=".4s">
              Education :: ERP :: CRM :: SME
            </p>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".2s">
                <img src={alpha} />

                <div className="content">
                  <h4>Horac Learning</h4>
                  <p>
                    360 degree school information management system for all
                    schools.
                  </p>
                  <ul>
                    <li>Tuition Collections</li>
                    <li>Online Learning</li>
                    <li>Reporting</li>
                    <li>Messaging</li>
                    <li>Administration</li>
                  </ul>
                  <a href="https://horacelearning.com/">
                    {" "}
                    <button className="btn btn-danger">Join Horace</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="icon-feature wow fadeInUp" data-wow-delay=".4s">
                <img src={redx} />

                <div className="content">
                  <h4>Cars & Parts</h4>
                  <p>Buy and sell affordable cars online.</p>
                  <ul>
                    <li>Find sellers near you</li>
                    <li>Chat with seller </li>
                    <li>Test the car</li>
                    <li>Pay secure</li>
                    <li>Browse inventory</li>
                  </ul>
                  <a
                    href="https://play.google.com/store/apps/details?id=net.myeverlasting.cnp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="btn btn-default">
                      Download carsNparts
                    </button>{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="mockup">
            <img
              className="shadow wow zoomIn animated"
              src={pshadow}
              alt="shadow"
            />
            <img
              className="phone wow fadeInDown animated"
              src={pphone}
              alt="phone"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Solution;
