import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
	Avatar,
	Container,
	CssBaseline,
	Grid,
	makeStyles,
	Toolbar,
	Tooltip,
	Typography,
} from '@material-ui/core';
import Navi from '../Navi';
import { posts } from './posts';
import { Helmet } from 'react-helmet';
import FeaturedPost from './FeaturedPost';
import avatar from '../../assets/img/steve.jpg';
import LinkIcon from '@material-ui/icons/Link';
import { Twitter, GitHub, LinkedIn } from '@material-ui/icons';
import { notifyInfo } from '../notification';
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom';

const useAnalyticsEventTracker = (category = 'Blog category') => {
	const eventTracker = (action = 'test action', label = 'test label') => {
		ReactGA.event({ category, action, label });
	};
	return eventTracker;
};

const useStyles = makeStyles((theme) => ({
	mainPost: {
		position: 'relative',
		backgroundColor: theme.palette.grey[800],
		color: theme.palette.common.white,
		marginBottom: theme.spacing(4),
		backgroundImage: 'url(https://placeholder.com/250)',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
	},
	overlay: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
	},
	mainContent: {
		marginTop: '100px',
		position: 'relative',
		padding: theme.spacing(3),
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(6),
			paddingRight: 0,
		},
	},
	contentSection: {
		fontSize: '20px',
		lineHeight: '1.5',
		fontWeight: '400',
		color: '#000',
		marginBottom: '1.5rem',
		marginTop: '1.5rem',
		textAlign: 'justify',
	},
	toolbarContainer: {
		display: 'flex',
		flexGrow: 1,
		alignItems: 'center',

		'@media (max-width: 600px)': {
			display: 'block',
		},
	},
	authorProfile: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',

		'@media (max-width: 600px)': {
			margin: 10,
		},
	},
	authorName: {
		marginLeft: theme.spacing(2),
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		'@media (max-width: 600px)': {
			justifyContent: 'flex-start',
			alignItems: 'flex-start',
			margin: '20px 0',
		},
	},
	listContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '5px',
		padding: 0,
	},
	listItem: {
		listStyle: 'none',
		margin: '0 10px',
	},
	joinCourse: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		backgroundColor: '#000',
		color: '#fff',
		borderRadius: '5px',
		padding: '10px 15px',
		margin: '20px 0',
	},
}));

const MainPost = () => {
	const params = useParams();
	const classes = useStyles();
	const history = useHistory();
	const post = posts.find((post) => post.id === +params.id);
	const moreReads = posts.filter((post) => post.id !== +params.id);
	const gaEventTracker = useAnalyticsEventTracker(`Blog Details Page`);
	if (post === undefined || post === null) {
		history.push('/blogs');
	}

	const readingTime = () => {
		const text = post?.content;
		const wpm = 225;
		const words = text?.trim().split(/\s+/).length;
		const time = Math.ceil(words / wpm);
		return time;
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
		gaEventTracker(`Blog ${post?.title}`);
	}, [post]);

	return (
		<>
			<Helmet>
				<title>ESSL - Blog</title>
				<meta
					name="description"
					content={`${
						post.brief || 'Blog for Everlasting Systems and Solutions LLC'
					}`}
				></meta>
				<meta
					name="keywords"
					content="blog,web, mobile, applications, software development, ESSL"
				/>
			</Helmet>
			<CssBaseline />
			<Navi navType="dark" />
			<Container maxWidth="lg">
				<main className="mt-5 main-section-nobg parallax">
					{/* <Paper
						className={classes.MainPost}
						style={{ backgroundImage: `url(${post?.image})` }}
					> */}

					<div>
						{/* <div className={classes.overlay}> */}
						<Grid container>
							<Grid item xs={2}></Grid>
							<Grid item md={8}>
								<div className=" parallax">
									<div className="mb-4 border">
										<Toolbar>
											<div className={classes.toolbarContainer}>
												<div className={classes.authorProfile}>
													<div>
														<Avatar alt="Remy Sharp" src={avatar} />
													</div>
													<div className={classes.authorName}>
														<Typography variant="subtitle1" color="inherit">
															{post.author?.name}
														</Typography>
														<Typography variant="body2" color="inherit">
															{post.author?.bio} - {readingTime()} min read
														</Typography>
													</div>
												</div>

												<div className={classes.list}>
													<div>
														<ul className={classes.listContainer}>
															<li className={classes.listItem}>
																<a
																	target="_blank"
																	rel="noreferrer"
																	href={`https://linkedin.com/in/${post.socials?.linkedin}`}
																>
																	<Tooltip title="linkedin" arrow>
																		<LinkedIn />
																	</Tooltip>
																</a>
															</li>
															<li className={classes.listItem}>
																<a
																	target="_blank"
																	rel="noreferrer"
																	href={`https://twitter.com/${post.socials?.twitter}`}
																>
																	<Tooltip title="twitter" arrow>
																		<Twitter />
																	</Tooltip>
																</a>
															</li>
															<li className={classes.listItem}>
																<a
																	target="_blank"
																	rel="noreferrer"
																	href={`https://github.com/${post.socials?.github}`}
																>
																	<Tooltip title="github" arrow>
																		<GitHub />
																	</Tooltip>
																</a>
															</li>
															<li
																className={classes.listItem}
																onClick={() => {
																	navigator.clipboard.writeText(
																		window.location.href
																	);
																	notifyInfo('Link Copied');
																}}
															>
																<Tooltip title="copy link" arrow>
																	<LinkIcon />
																</Tooltip>
															</li>
														</ul>
														<Typography
															variant="body2"
															color="inherit"
															style={{ padding: '0 10px' }}
														>
															{new Date(post?.updatedAt).toDateString()}
														</Typography>
													</div>
												</div>
											</div>
										</Toolbar>
									</div>
									{/* <div className={classes.mainContent}> */}
									<div>
										<Typography
											component="h1"
											variant="h3"
											color="inherit"
											gutterBottom
										>
											{post?.title}
										</Typography>
										<div className="section-header">
											<h2
												style={{
													lineHeight: '1.5',
												}}
											>
												{post?.brief}
											</h2>
										</div>
									</div>
									<img
										src={
											post?.image?.startsWith('http')
												? post.image
												: `/img/${post.image}`
										}
										alt={post?.imageText}
										style={{
											margin: '50px auto',
											display: 'block',
											maxWidth: '100%',
											maxHeight: '100%',
										}}
									/>

									<div
										className={classes.contentSection}
										color="inherit"
										dangerouslySetInnerHTML={{ __html: post?.content }}
									/>
									{/* <Link variant="subtitle1" href="#">
                  {post?.linkText}
                </Link> */}
									{post?.title && (
										<div className={classes.joinCourse}>
											<Typography component="h3" variant="h5" className="my-3">
												Join our{' '}
												<Link
													to={`/course/apply?type=${post.title}`}
													className=" wow fadeInUp badge-color"
													data-wow-delay="1s"
												>
													{post?.title}
												</Link>{' '}
												classroom for comprehensive hands-on training.
											</Typography>
										</div>
									)}
								</div>
							</Grid>
							<Grid item xs={2}></Grid>
						</Grid>
					</div>
					<div>
						<Grid container spacing={4}>
							{moreReads.map((itm, idx) => (
								<FeaturedPost key={idx} post={itm} />
							))}
						</Grid>
					</div>

					{/* </Paper> */}
				</main>
			</Container>
		</>
	);
};

export default MainPost;
