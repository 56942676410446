import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { AuthNavi } from "../Navi";

const useStyles = makeStyles(() => ({
  container: {
    marginTop: "100px",
  },
  left: {
    backgroundColor: "#f5f5f5",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  right: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "50px",
  },
  illustration: {
    width: "80%",
    margin: "10px auto",
  },
}));
export const AuthLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <AuthNavi navType="dark" link="sign-in" title="sign-in" />
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sm={4} className={classes.left}>
          <Box>
            <img
              src="img/new-entry.png"
              alt="An illustration of woman registering on her ipad"
              className={classes.illustration}
            />
            <Typography align="center" variant="h4" component="h1" gutterBottom>
              Sharing Product
            </Typography>
            <Typography align="center" variant="h5" component="h2" gutterBottom>
              Share your product with the world...
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.right}>
          {children}
        </Grid>
      </Grid>
    </>
  );
};
