import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/css/animate.min.css';
import './assets/css/ionicons.min.css';
import './assets/css/linea.css';
import './assets/css/waves.min.css';
import './assets/css/owl.carousel.css';
import './assets/css/magnific-popup.css';
import './assets/css/style.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './view/Home';
import Career from './view/Career';
import Footer from './component/Footer';

import Owope from './view/payment/Owope';
import MainPost from './component/blog/MainPost';
import CareerForm from './component/CareerForm';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import ReactGA from 'react-ga';
import Domainstore from './view/storefront/Domainstore';
import Igbo from './view/payment/Igbo';
import { AppProvider } from './context/AppProvider';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Privacy from './view/dfm/Privacy';
import Videoplayer from './view/dfm/Videoplayer';
import Blogs from './view/Blogs';
import ScrollToTop from './ScrollToTop';
import Pricing from './component/Pricing';
import RegCourse from './view/RegCourse';
import Signup from './view/Signup';
import SignIn from './view/SignIn';

export const theme = createMuiTheme({
	typography: {
		fontFamily: ['Lato', 'sans-serif'].join(','),
	},
});
const NotFound = () => (
	<div
		style={{ margin: '0 auto', width: '50%', textAlign: 'center', padding: 10 }}
	>
		<h1>Sorry, this page is not available</h1>
	</div>
);
function App() {
	ReactGA.initialize('UA-236869093-1');
	React.useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, [window.location.href]);
	return (
		<ThemeProvider theme={theme}>
			<AppProvider>
				<Router>
					<ScrollToTop>
						<Switch>
							<Route exact path="/" component={Home} />
							<Route exact path="/careers" component={Career} />
							<Route exact path="/careers/apply" component={CareerForm} />
							<Route exact path="/course/apply" component={RegCourse} />
							<Route exact path="/blogs" component={Blogs} />
							<Route path="/blog/:id" component={MainPost} />
							<Route exact path="/privacy/dfm" component={Privacy} />
							<Route exact path="/success" component={Owope} />
							<Route exact path="/cancel" component={Igbo} />
							<Route exact path="/pricing" component={Pricing} />
							<Route exact path="/sign-up" component={Signup} />
							<Route exact path="/sign-in" component={SignIn} />
							<Route
								exact
								path="/store/domain/:domain"
								component={Domainstore}
							/>
							<Route exact path="/dfm/player" component={Videoplayer} />
							<Route component={NotFound} />
						</Switch>
					</ScrollToTop>
				</Router>
				<ToastContainer theme="colored" autoClose={3000} />
			</AppProvider>
			<Footer />
		</ThemeProvider>
	);
}

export default App;
