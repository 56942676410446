import React from 'react';
import { useDropzone } from 'react-dropzone';
import uploadpic from '../assets/img/uploadpic.png';
import DeleteIcon from '@material-ui/icons/Delete';

export function DocumentUploadInput(props) {
	const { getInputProps, getRootProps, isDragActive } = useDropzone({
		...props.dropzoneOpt,
	});

	return (
		<div
			{...getRootProps()}
			className="d-flex flex-column align-items-center justify-content-center dashed-border"
		>
			<span className=" fs-5 fw-normal text-secondary ">
				<div className=" my-2">
					<label className="d-flex flex-column justify-content-center align-items-center">
						<img
							alt="upload-cloud"
							src={uploadpic}
							className="my-2"
							width="120"
							height="100"
						/>

						<input {...getInputProps()} />
						<label className="text-secondary my-3">
							{isDragActive ? (
								<p className="text-secondary">Drop the files here ...</p>
							) : (
								<span className="text-secondary">
									Click to upload, or Drag and Drop {props.title}
								</span>
							)}
						</label>
						<span className=" text-secondary">
							{props.label ||
								'The format should be PDF or Word document (Max 5MB)'}
						</span>
					</label>
				</div>
			</span>
		</div>
	);
}

export function DocumentUploadItem({ label, onClick }) {
	return (
		<div className="d-flex justify-content-between align-items-center dashed-border px-5 py-3 my-3">
			<span className="text-center text-secondary fs-5">{label}</span>
			<div onClick={onClick} className="cursor-pointer">
				<DeleteIcon size={30} />
			</div>
		</div>
	);
}
