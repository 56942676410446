import React, { useState } from 'react';
import {
	Container,
	Nav,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
	Collapse,
	Row,
	Col,
} from 'reactstrap';
import logo from '../assets/img/logo.png';
import { NavHashLink } from 'react-router-hash-link';

const Navi = (props) => {
	const [open, setOpen] = useState(false);
	const { navType = 'dark' } = props;

	const toggle = () => setOpen(!open);

	// console.log(props.navType);
	return (
		<Navbar expand="md" color={navType} fixed="top">
			<Container>
				<NavbarBrand href="/">
					<img
						src={logo}
						alt=""
						style={{ filter: 'saturate(3)', paddingBottom: '-10px' }}
					/>
				</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={open} navbar>
					<Nav className="ml-auto" navbar>
						<NavItem>
							<NavLink>
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/#home'}
									className="navbar-item"
								>
									Home
								</NavHashLink>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink>
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/#feature'}
									className="navbar-item"
								>
									What We Do
								</NavHashLink>
							</NavLink>
						</NavItem>

						<NavItem>
							<NavLink>
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/#solution'}
									className="navbar-item"
								>
									Solutions
								</NavHashLink>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink>
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/#pricing'}
									className="navbar-item"
								>
									Pricing
								</NavHashLink>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink>
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/#blog'}
									className="navbar-item"
								>
									Blogs
								</NavHashLink>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink>
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/careers'}
									className="navbar-item"
								>
									Careers
								</NavHashLink>
							</NavLink>
						</NavItem>

						<NavItem>
							<NavLink>
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/#contact'}
									className="navbar-item"
								>
									Contact
								</NavHashLink>
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Container>

			{/* <div class='navbar-header' >

    <button type='button' class='navbar-toggle collapsed' data-toggle='collapse' data-target='#nav-collapse' aria-expanded='false'>
        <span class='sr-only' >Toggle navigation</span>
        <span class='icon-bar' ></span>
        <span class='icon-bar' ></span>
        <span class='icon-bar' ></span>
    </button>

    <a href='#' class='navbar-brand' >
        <img class='logo-light' src='img/logo.png' alt>
        <img class='logo-dark' src='img/logo-dark.png' alt>
    </a>

</div> */}
		</Navbar>
	);
};

export default Navi;

export const AuthNavi = (props) => {
	const [open, setOpen] = useState(false);
	const { navType = 'dark', title, link } = props;

	const toggle = () => setOpen(!open);
	return (
		<Navbar expand="md" color={navType} fixed="top">
			<Container>
				<Row>
					<Col style={{ flexGrow: '1' }}>
						<NavbarBrand href="/">
							<img
								src={logo}
								alt=""
								style={{
									filter: 'saturate(3)',
									paddingBottom: '-10px',
								}}
							/>
						</NavbarBrand>
					</Col>
					<Col>
						<NavbarToggler onClick={toggle} />
						<Collapse isOpen={open} navbar>
							<Nav className="ml-auto" navbar>
								<NavItem>
									<NavLink>
										<NavHashLink
											smooth
											activeStyle={{ fontWeight: 'bold' }}
											to={`/${link}`}
											className="navbar-item app-btn"
										>
											{title}
										</NavHashLink>
									</NavLink>
								</NavItem>
							</Nav>
						</Collapse>
					</Col>
				</Row>
			</Container>
		</Navbar>
	);
};
