import React, { createContext, useReducer } from 'react'
const initialState = {
  pdt: { item: '', cost: 0 },
  cost: 0,
}
const Appcontext = createContext(initialState)
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_COST':
        return {
          ...state,
          cost: action.cost,
        }
      case 'SET_PDT':
        return {
          ...state,
          pdt: action.pdt,
        }
      default:
        return state
    }
  }, initialState)
  return (
    <Appcontext.Provider value={[state, dispatch]}>
      {children}
    </Appcontext.Provider>
  )
}

export { AppProvider, Appcontext }
