export const posts = [
	{
		id: 1,
		updatedAt: '2022-03-01T00:00:00.000Z',
		socials: {
			linkedin: 'isah-ibrahim',
			github: 'ibrahim-isah',
			twitter: 'dev_abba',
		},
		author: {
			name: 'Babafemi Adigun',
			image: 'https://avatars.githubusercontent.com/u/42979423?v=4',
			bio: 'Fullstack Engineer',
		},
		category: ['Software Development', 'Testing'],
		title: 'Software Testing and Project Failure',
		thumb: 'test.png',
		image:
			'https://static5.depositphotos.com/1010683/498/i/450/depositphotos_4985435-stock-photo-workplace-room-with-computers.jpg',
		imgText: '',
		brief: `A major problem that has plagued
    most projects is the issue of delivering an efficient project with no bugs or
    errors.`,
		content: `<div>

        <p style={{lineHeight: '150%'}}>Why Projects Fail: Testing</p>
        
        <p style={{lineHeight: '150%'}}><span>A major problem that has plagued
        most projects is the issue of delivering an efficient project with no bugs or
        errors. This problem is as old as the software industry itself, and I will like
        to assume that this problem gave birth to the project management office and the
        project management profession. According to Singh (2011), software testing involves
        testing newly developed software prior to its actual use. Inputs and
        computational logics are simulated to mimic real life usage, and the test results
        should capture all errors and oversights in the project.</span></p>
        
        <p style={{textIndent:'.5in';lineHeight:'150%''><span
        style='mso-bookmark:OLE_LINK2'><span style='mso-bookmark:OLE_LINK1'>Test Driven
        Development, a popular feature of Extreme Programming (XP) and Agile
        methodologies has greatly impacted the software development practice and
        drastically reduced the amount defects in modern software compared to legacy
        software. Unit tests check the individual components of the software while
        integration tests ensure that each of the components are tested and functions end-to-end
        as expected. Also, Test Driven Development (TDD) expects developers to write
        failing tests firsts, then write the implementation logic of the software to
        pass the tests. This practice is efficient in reducing or eliminating software
        failure although it still depends to some extents on the competency of the
        developers and the scope of the software. Lazy and average developers will
        still write average tests which may give a false sense of reliability and
        efficiency to the project. An efficient test suite must cover all the essential
        aspects of the project scope, and testing should be considered as an integral
        part and a mandatory feature in every project to reduce project failure.</span></span></p>
        
        <p style='text-indent:.5in;line-height:150%'><span
        style='mso-bookmark:OLE_LINK2'><span style='mso-bookmark:OLE_LINK1'>Although a
        developer can only write tests based on perceived or expected failure points in
        the software, a software project with adequate tests may still fail due to a
        phenomenon called developer blind spot. Features, logics or exceptions that the
        developer is not aware of or lacks the necessary skills to write test cases for
        may still lead to project failure. Some organizations still wrongly believe
        software testing is a waste of resources and man hours, and as such places
        little or no emphasis on mandatory units and integration testing. A major
        reason why projects fail is not writing adequate and efficient test plans and I
        suggest testing should be a mandatory and also treated as an integral part of
        every project to achieve a certain degree of calculated success. </span></span></p>
        
        <p style='text-indent:.5in;line-height:150%'><span
        style='mso-bookmark:OLE_LINK2'><span style='mso-bookmark:OLE_LINK1'><span
        style='mso-spacerun:yes'></span><span style='mso-spacerun:yes'></span></span></span></p>
        
        <p style={{lineHeight: '150%'}}><span style='mso-bookmark:OLE_LINK2'><span
        style='mso-bookmark:OLE_LINK1'>References:</span></span></p>
        
        <p style={{lineHeight: '150%'}}><span style='mso-bookmark:OLE_LINK2'><span
        style='mso-bookmark:OLE_LINK1'>Singh, Y. (2011).&nbsp;<i>Software Testing</i>.
        Cambridge University Press.</span></span></p>
        
        <span style='mso-bookmark:OLE_LINK1'></span><span style='mso-bookmark:OLE_LINK2'></span>
        
        <p style={{lineHeight: '150%'}}><o:p>&nbsp;</o:p></p>
        
        </div>`,
	},
	{
		id: 2,
		updatedAt: '2022-03-01T00:00:00.000Z',
		socials: {
			linkedin: 'isah-ibrahim',
			github: 'ibrahim-isah',
			twitter: 'dev_abba',
		},
		author: {
			name: 'Isah Ibrahim',
			image: 'https://avatars.githubusercontent.com/u/42979423?v=4',
			bio: 'Software Engineer',
		},
		category: ['Computing'],
		title: 'Quantum Computing',
		thumb: 'thumb-quantum.png',
		image:
			'https://static5.depositphotos.com/1010683/498/i/450/depositphotos_4985435-stock-photo-workplace-room-with-computers.jpg',
		imgText: '',
		brief: `Quantum Computing is simply the union of ideas between classical computing, quantum physics, and information theory`,
		content: `<div>
    <p><span data-preserver-spaces="true">
    <span>Quantum Computing is simply the union of ideas between classical computing, quantum physics, and information theory. Classical computing is limited, and we need to transcend the binary representation of data to enable our computers to perform multiple complex calculations &nbsp;</span></p>
    <p><span data-preserver-spaces="true" >Quantum Computing is not a new science, contrary to the speaker&rsquo;s claim and many quantum processors and algorithms have been built already. Andrew S.(1998), in an article submitted as far back as 1997, reported that quantum computing dates back to the mid 20th century. Quantum computing(2018) reported that the first quantum computers were built in 1998 by a group of researchers at Oxford University.&nbsp;</span></p>
    <p><span data-preserver-spaces="true" >We indeed need more powerful computers to analyze and simulate some microorganisms and molecules.&nbsp;</span></p>
    <p><span data-preserver-spaces="true" >Beyond the health sector, Quantum computing will completely revolutionize the manufacturing sector, agriculture sector, weather and climate studies, and the financial sector. It&apos;s high time we began to think beyond zeros and ones. IBM launched a 53-qubit quantum computer in October 2018, as reported by the Technology Review website (</span><a class="editor-rtfLink" href="https://technologyreview.com/" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt; color: #4a6ee0;" target="_blank"><span data-preserver-spaces="true" style="color: rgb(14, 16, 26); background: transparent; margin-top:0pt; margin-bottom:0pt; color: #4a6ee0;">https://technologyreview.com</span></a><span data-preserver-spaces="true" >) Emily Price also reported on the Digital Trends website that Google&rsquo;s Sycamore quantum computer had achieved computing capabilities. We are closer to the solution to the problem coursed by the limitation of our conventional computers than the speaker made us believe. &nbsp;</span></p>  </div>`,
	},
	{
		id: 3,
		updatedAt: '2022-10-01T00:00:00.000Z',
		socials: {
			linkedin: 'isah-ibrahim',
			github: 'ibrahim-isah',
			twitter: 'dev_abba',
		},
		author: {
			name: 'Femi Adigun',
			image: 'https://avatars.githubusercontent.com/u/42979423?v=4',
			bio: 'Software Engineer',
		},
		category: ['Career', 'Beginner', 'UI.UX'],
		title: 'Careers in ICT: UI/UX Development',
		thumb: 'ux.jpeg',
		image: 'ux.jpeg',
		imgText: '',
		brief: `Commonly referred to as a UI / UX designer, this plays a critical role in the design and flow of any type of system being built or upgraded.`,
		content: `<div><p>UI is a user interface, and UX is user experience. This role is ideal for anyone with a great eye for design and drawing and may even have had past graphic design experience.</p> 
    <p>The UI part is all about making the graphics and visuals look outstanding and appealing to the intended user. It covers what the right color scheme on each page of the application is for the web, desktop, and mobile versions.</p>
    <p> One needs to be creative here and ensure that while appealing, it also has a uniform look and feel. </p>
    <p>The UX part is about the user experience and flow. It's about getting into the mindset of what the target user is, whether it's a customer or someone working within the company, and building out a logical and efficient flow for them. </p>
    <p>It's important to understand what the user pain points would be and how a good and thorough flow helps to alleviate those issues. UX means understanding where every button goes and needs to know all the different pages of the system. This is a required component of creating wireframes which front-end developers will need to begin the coding and development process.</p> </div>`,
	},
	{
		id: 4,
		updatedAt: '2022-11-01T00:00:00.000Z',
		socials: {
			linkedin: 'babafemiwebmaster',
			github: 'babaphemy',
			twitter: 'babafemiadigun',
		},
		author: {
			name: 'Femi Adigun',
			image: 'https://avatars.githubusercontent.com/u/42979423?v=4',
			bio: 'Software Engineer',
		},
		category: ['Career', 'Projects', 'Beginner'],
		title: 'Careers in ICT: Project Management',
		thumb: 'scrum.png',
		image: 'scrum.png',
		imgText: '',
		brief: `According PMI website, Project Management is the use of specific knowledge, skills, tools and techniques to deliver value in projects.`,
		content: `<div><p>On this side of the spectrum, it's all about sticking to the task and delivering in a timely fashion within the approved budget. Project managers are a critical component for any new or existing project to keep the project moving and manage those expectations of deliverables with key stakeholders.</p> 
    <p>The project manager's responsibility is to get the right requirements, develop a realistic timeline for iterative and cremental project delivery, break the project down into tiny parts such as features, tasks, user stories and epics.</p>
    <p>He or she is also build out a schedule and ensure team members stricly adhere to this. They are the guards against going outside the scope, known as scope creep, and there to understand why timelines were missed or additional funding is needed.</p>
    <p>Project managers need to have an overall bird's eye view of the whole end-to-end project, know when to close out certain parts, and when to integrate new members into the team. Many project managers follow similar guidelines and product or system development lifecycles to ensure that no project gets stuck. 
    They make sure to take the time to initiate and design the project with the key participants, help to execute that plan, and then monitor and control the project's results before closing it out and moving on to the next project. </p></div>`,
	},
];
