import React, { useEffect, useState } from 'react';
import loader from '../assets/img/loader.gif';
import Intro from '../component/home/intro';
import Feature from '../component/Feature';
import Contact from '../component/Contact';
import Solution from '../component/Solution';
import wow from 'wow.js';
import Navi from '../component/Navi';
import { Helmet } from 'react-helmet';
import Blogsection from '../component/blog/Blogsection';

const Home = () => {
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(false);
		const wo = new wow();
		wo.init();
	}, []);

	return (
		<div>
			<Helmet>
				<title>ESSL - Home</title>
				<meta
					name="description"
					content="Homepage for Everlasting Systems and Solutions LLC"
				></meta>
				<meta
					name="keywords"
					content="web, mobile, applications, software development, ESSL"
				/>
			</Helmet>
			{loading ? (
				<div id="preloader">
					<div className="loader">
						<img src={loader} alt="loader" />
					</div>
				</div>
			) : (
				<>
					<Navi />
					<div id="home">
						<Intro />
					</div>
					<div id="feature">
						<Feature />
					</div>

					<div id="solution">
						<Solution />
					</div>
					{/* <div id="pricing">
            <Pricing />
          </div> */}
					<div id="blog">
						<Blogsection />
					</div>
					<div id="contact">
						<Contact />
					</div>
				</>
			)}
		</div>
	);
};

export default Home;
