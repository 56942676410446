import React from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Navi from '../../component/Navi';

const Privacy = () => {
	return (
		<>
			<Helmet>
				<title>ESSL - Blog</title>
				<meta
					name="description"
					content="Blog for Everlasting Systems and Solutions LLC"
				></meta>
				<meta
					name="keywords"
					content="blog,web, mobile, applications, software development, ESSL"
				/>
			</Helmet>
			<CssBaseline />
			<Navi navType="dark" />
			<Container>
				<div className="row main-section-nobg parallax" id="intro">
					<div className="col-md-12 d-flex flex-column justify-content-center align-items-center">
						<h1 className="text-center">
							Privacy Policy [Dear Future Millionaire]
						</h1>
						<main className="col-md-8 ">
							<div id="paragraph">
								<p>
									This privacy policy ("Policy") will help you understand how
									[name] ("us", "we", "our") uses and protects the data you
									provide to us when you visit and use “Dear Future Millionaire”
									the app. We reserve the right to change this policy at any
									given time, of which you will be promptly updated. If you want
									to make sure that you are up to date with the latest changes,
									we advise you to frequently visit this page.
								</p>
								<p>
									We reserve the right to change this policy at any given time,
									of which you will be promptly updated. If you want to make
									sure that you are up to date with the latest changes, we
									advise you to frequently visit this page.
								</p>
							</div>
							<div id="whatwecollect">
								<h3>What User Data We Collect</h3>
								<p>
									When you visit the app, we may collect the following data:
								</p>
								<ul>
									<li>Your IP address.</li>
									<li>Your contact information and email address.</li>
									<li>Other information such as interests and preferences.</li>
									<li>
										Data profile regarding your online behavior on our website.
									</li>
								</ul>
							</div>
							<div id="whywecollect">
								<h3>Why We Collect Your Data</h3>
								<p>We are collecting your data for several reasons:</p>
								<ul>
									<li>To better understand your needs.</li>
									<li>To improve our services and products.</li>
									<li>
										To send you promotional emails containing the information we
										think you will find interesting.
									</li>
									<li>
										To contact you to fill out surveys and participate in other
										types of market research.
									</li>
									<li>
										To customize our website according to your online behavior
										and personal preferences.
									</li>
								</ul>
							</div>
							<div id="safeguarding">
								<h3>Safeguarding and Securing the Data</h3>
								<p>
									“Dear future millionaire” is committed to securing your data
									and keeping it confidential. “Dear future millionaire” has
									done all in its power to prevent data theft, unauthorized
									access, and disclosure by implementing the latest technologies
									and software, which help us safeguard all the information we
									collect online.
								</p>
							</div>
							<div id="cookie-policy">
								<h3>Our Cookie Policy</h3>
								<p>
									Once you agree to allow our website to use cookies, you also
									agree to use the data it collects regarding your online
									behavior (analyze app traffic, app screens you spend the most
									time on, and similar apps you visit).
								</p>
								<p>
									The data we collect by using cookies is used to customize our
									app to your needs. After we use the data for statistical
									analysis, the data is completely removed from our systems.
								</p>
								<p>
									Please note that cookies don't allow us to gain control of
									your phone in any way. They are strictly used to monitor which
									pages you find useful and which you do not so that we can
									provide a better experience for you.
								</p>
								<p>
									If you want to disable cookies, you can do it by accessing the
									settings of your phone’s security settings.
								</p>
							</div>
							<div id="otherapps">
								<h3>Links to Other Apps</h3>
								<p>
									Our App may contain links that lead to other services. If you
									click on these links, “Dear future millionaire” is not held
									responsible for your data and privacy protection. Visiting
									those links is not governed by this privacy policy agreement.
									Make sure to read the privacy policy documentation of the
									links you go to from our app.
								</p>
							</div>
							<div id="protect-personal-data">
								<h3>Restricting the Collection of your Personal Data</h3>
								<p>
									At some point, you might wish to restrict the use and
									collection of your personal data. You can achieve this by
									doing the following:
								</p>
								<p>
									When you are filling the signup forms on the app, make sure to
									check if there is a box that you can leave unchecked, if you
									don't want to disclose your personal information.
								</p>
								<p>
									If you have already agreed to share your information with us,
									feel free to contact us via email (webmaster@myeverlasting.net
									and we will be more than happy to change this for you.
								</p>
								<p>
									“Dear Future Millionaire” will not lease, sell or distribute
									your personal information to any third parties unless we have
									your permission. We might do so if the law forces us. Your
									personal information will be used when we need to send you
									promotional materials if you agree to this privacy policy.
								</p>
							</div>
						</main>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Privacy;
