import React from 'react';
import {
	Box,
	Button,
	Container,
	CssBaseline,
	Modal,
	Typography,
} from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Navi from '../component/Navi';
import buildings from '../assets/img/buildings.jpg';
import { NavHashLink } from 'react-router-hash-link';
import wow from 'wow.js';
import SearchIcon from '@material-ui/icons/Search';
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};
const Career = () => {
	const [open, setOpen] = React.useState(false);
	React.useEffect(() => {
		window.scrollTo(0, 0);
		const wo = new wow();
		wo.init();
	}, []);
	return (
		<>
			<Helmet>
				<title>ESSL - Blog</title>
				<meta
					name="description"
					content="Blog for Everlasting Systems and Solutions LLC"
				></meta>
				<meta
					name="keywords"
					content="blog,web, mobile, applications, software development, ESSL"
				/>
			</Helmet>
			<CssBaseline />
			<Navi navType="dark" />

			<section
				className="parallax"
				data-parallax="scroll"
				style={{
					background: `url(${buildings})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
				}}
			>
				<main
					style={{
						backgroundColor: 'rgba(30,40,80,0.5)',
						height: '100%',
					}}
				>
					<div
						id="intro"
						className="main-section parallax"
						data-parallax="scroll"
					>
						<div className="parallax text-center text-white col-md-12 d-flex flex-column justify-content-center align-items-center">
							<div className="section-header ">
								<h1 className="wow fadeInUp  " data-wow-delay=".2s">
									Join Our Team
								</h1>
								<p
									className="wow fadeInUp fw-bold fs-2 text-white"
									data-wow-delay=".4s"
								>
									Let's start something big together.
								</p>
							</div>
							<div className="w-50 mt-5 text-center">
								<p className="wow slideInLeft fs-3" data-wow-delay=".2s">
									We explore ways to build scalable and easy to use digital
									solutions for our clients
								</p>
							</div>
							<div className="mt-5 btns">
								<NavHashLink
									smooth
									activeStyle={{ fontWeight: 'bold' }}
									to={'/careers/#jobs'}
									className="app-btn wow bounceIn"
									data-wow-delay=".8s"
								>
									View Jobs
								</NavHashLink>
							</div>
						</div>
					</div>
				</main>
			</section>
			<Container maxWidth="lg">
				<div id="jobs" className="mt-5 mb-5 parallax ">
					<div className="mt-5 mb-5 parallax section-header text-center d-flex flex-column justify-content-center align-items-center">
						<h2 className="wow fadeInUp  " data-wow-delay=".2s">
							Working at ESSL
						</h2>
						<div className="w-75 mt-5 text-center">
							<p className="wow slideInLeft fs-3" data-wow-delay=".2s">
								Across the company, we are excited about delivering a product
								that our customers love. We promote a healthy work-life balance,
								career growth, and friendly remote culture.
							</p>
						</div>
					</div>
					<div className="section-header text-center">
						<h2 className="wow fadeInUp" data-wow-delay=".2s">
							Jobs
						</h2>
						<p className="wow fadeInUp" data-wow-delay=".4s">
							Currently hiring for-
						</p>
					</div>
					<div className="d-flex form-control justify-content-between align-items-center my-3">
						<input
							type="text"
							placeholder="Search Jobs"
							className="border-0 w-100 form-control py-0 flex-4"
						/>
						<div className="cursor-pointer ">
							<SearchIcon size={30} />
						</div>
					</div>
					<div className="row mb-5 justify-content-center align-items-center">
						{sampleJobs.map((job, index) => (
							<JobCards key={index} job={job} setOpen={() => setOpen(true)} />
						))}
					</div>
					<div>
						<button
							className="form-control btn-custom wow fadeInUp w-100 my-3 badge-color"
							data-wow-delay=".4s"
						>
							View More Jobs
						</button>
					</div>
				</div>
			</Container>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="job-description-modal"
				aria-describedby="job-description-modal"
			>
				<Box sx={style}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						Show selected Job here
					</Typography>
					<Typography variant="title">About The Position</Typography>
					<Typography id="modal-modal-description" sx={{ mt: 2 }}>
						Display requirements, description details , qualification, pay
						expectation etc here
					</Typography>
					<Typography variant="title">Requirements</Typography>
					<li>Requirement one</li>
					<li>Requirements two</li>
					<Button>Apply Now</Button>
				</Box>
			</Modal>
		</>
	);
};

export default Career;

export const JobCards = ({ job, setOpen }) => {
	return (
		<>
			<div className="col-md-4">
				<div
					className={`p-table wow ${
						Math.random() < 0.5 ? 'fadeInRight' : 'fadeInLeft'
					}`}
					data-wow-delay=".8s"
				>
					<div>
						<p className="fw-bold">{job.title}</p>
						<p className="fw-normal">{job.description}</p>
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<button
							onClick={() => setOpen(true)}
							className="text-decoration-none badge p-2 bg-primary text-wrap text-white"
						>
							Read more
						</button>
						<a
							href={`/careers/apply?jobType=${job.jobType}`}
							className="text-decoration-none badge text-wrap p-2 badge-color btn-custom"
						>
							Apply
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

const sampleJobs = [
	{
		title: 'Frontend Developer',
		jobType: 'SOFTWARE_ENGINEER',
		description:
			'We are looking for a frontend developer specializing on React and React Native Development',
	},
	{
		title: 'Product Manager',
		jobType: 'PRODUCT_MANAGER',
		description:
			'We have an open position for a prospective candidate that is good with managing teams and product',
	},
	{
		title: 'Product Owner',
		jobType: 'PRODUCT_OWNER',
		description:
			'We are looking for a talented person to manage and own products through our teams',
	},
];
