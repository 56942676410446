import React, { useEffect } from "react";
const Videoplayer = () => {
  useEffect(() => {
    async function getFile() {
      const options = {
        method: "GET",
        headers: {
          Accept: "*/*",
          AccessKey: "208274ef-e7a4-4d5d-82584b49d48f-74d4-4532",
        },
      };
      const f = await fetch(
        "https://la.storage.bunnycdn.com/essl/budgeting.mp4",
        options
      );
      console.log(f);
      return f;
    }
    getFile();
  }, []);
  return (
    <div style={{ position: "relative", paddingTop: "56.25%" }}>
      {/* <iframe
        src="https://iframe.mediadelivery.net/embed/59546/cdbe076c-eed4-4d28-a50b-78fb957b1244?autoplay=true"
        loading="lazy"
        style={{
          border: "none",
          position: "absolute",
          top: 0,
          height: "100%",
          width: "100%",
        }}
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowFullScreen="true"
      ></iframe> */}
    </div>
  );
};
export default Videoplayer;
