import React from "react";
import { Col, Container, Row } from "reactstrap";
import front2 from "../../assets/img/mockup1-back.png";
import front from "../../assets/img/mockup1-front.png";
import { NavHashLink } from "react-router-hash-link";

const Intro = () => {
  return (
    <section
      id="intro"
      className="main-section parallax"
      data-parallax="scroll"
    >
      <Container>
        <Row>
          <Col>
            <div className="intro-text">
              <h1 className="wow fadeInUp" data-wow-delay=".2s">
                Proven technology that adds value to your business!
              </h1>

              <p className="font-alt wow fadeInUp" data-wow-delay=".4s">
                With our amazing B2B applications
              </p>

              <div className="btns">
                <NavHashLink
                  smooth
                  activeStyle={{ fontWeight: "bold" }}
                  to={"/#contact"}
                  className="app-btn wow bounceIn"
                  data-wow-delay=".6s"
                >
                  <i className="ion-social-usd"></i>
                  Request A Quote
                </NavHashLink>

                <NavHashLink
                  smooth
                  activeStyle={{ fontWeight: "bold" }}
                  to={"/#feature"}
                  className="app-btn wow bounceIn"
                  data-wow-delay=".8s"
                >
                  <i className="ion-android-contact"></i>
                  Explore
                </NavHashLink>
              </div>
            </div>
          </Col>
          <Col>
            <div className="mockup">
              <img
                src={front}
                className="front wow fadeInDown"
                data-wow-duration=".7s"
                data-wow-delay="1.6s"
                alt="front"
                width="80%"
              />
              <img
                src={front2}
                className="back wow fadeInDown"
                data-wow-duration=".7s"
                data-wow-delay="1.3s"
                alt="front2"
                width="80%"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Intro;
