import React from 'react';
import { Container, CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Navi from '../component/Navi';
import CourseForm from '../component/CourseForm';

const RegCourse = () => {
	return (
		<>
			<Helmet>
				<title>ESSL - Blog</title>
				<meta
					name="description"
					content="Training Courses by Everlasting Systems and Solutions LLC"
				></meta>
				<meta
					name="keywords"
					content="training ,course, mobile, applications, software development, ESSL"
				/>
			</Helmet>
			<CssBaseline />
			<Navi navType="dark" />
			<Container maxWidth="lg">
				<CourseForm />
			</Container>
		</>
	);
};

export default RegCourse;
