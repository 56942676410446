import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { AuthLayout } from '../component/auth/Layout';

const Signup = () => {
	const [firstName, setFirstName] = React.useState('');
	const [lastName, setLastName] = React.useState('');

	return (
		<AuthLayout>
			<Box>
				<Typography
					variant="body1"
					component="p"
					gutterBottom
					style={{ fontWeight: 'bolder' }}
				>
					Step 1 of 3
				</Typography>
				<Typography
					variant="h4"
					component="h1"
					gutterBottom
					style={{ fontWeight: 'bolder' }}
				>
					Create Account
				</Typography>
				<Typography
					variant="body1"
					component="p"
					gutterBottom
					style={{ fontWeight: 'bolder' }}
				>
					Register your account to get started
				</Typography>
				<Box>
					<form
						id="contact-form"
						name="contactForm"
						className="contact-form"
						data-toggle="validator"
					>
						<div id="contact-form-result"></div>

						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<input
										type="text"
										className="form-control wow fadeInUp"
										data-wow-delay=".4s"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
										name="name"
										id="firstname"
										placeholder="firstname"
										required
									/>
									<div className="help-block with-errors"></div>
								</div>
								<div className="form-group">
									<input
										type="text"
										className="form-control wow fadeInUp"
										data-wow-delay=".4s"
										value={lastName}
										onChange={(e) => setLastName(e.target.value)}
										name="name"
										id="lastname"
										placeholder="lastname"
										required
									/>
									<div className="help-block with-errors"></div>
								</div>
							</div>
						</div>
					</form>
				</Box>
				<Typography
					variant="body1"
					component="p"
					gutterBottom
					style={{ fontWeight: 'bold' }}
				>
					This information will be stored as per our privacy policy and Terms of
					Use
				</Typography>
			</Box>
		</AuthLayout>
	);
};

export default Signup;
