import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { AuthLayout } from '../component/auth/Layout';

const SignIn = () => {
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	// const [error, setError] = React.useState('');

	return (
		<AuthLayout>
			<Box>
				<Typography
					variant="h4"
					component="h1"
					gutterBottom
					style={{ fontWeight: 'bolder' }}
				>
					Login to your account
				</Typography>
				<Typography
					variant="body1"
					component="p"
					gutterBottom
					style={{ fontWeight: 'bolder' }}
				>
					Register your account to get started
				</Typography>
				<Box>
					<form
						id="contact-form"
						name="contactForm"
						className="contact-form"
						data-toggle="validator"
					>
						<div id="contact-form-result"></div>

						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<input
										type="email"
										className="form-control wow fadeInUp"
										data-wow-delay=".4s"
										name="email"
										id="email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										placeholder="email"
										required
									/>
									<div className="help-block with-errors"></div>
								</div>
								<div className="form-group">
									<input
										type="password"
										className="form-control wow fadeInUp"
										data-wow-delay=".4s"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										name="password"
										id="password"
										placeholder="password"
										required
									/>
									<div className="help-block with-errors"></div>
								</div>
							</div>
						</div>
					</form>
				</Box>
				<Typography
					variant="body1"
					component="p"
					gutterBottom
					style={{ fontWeight: 'bold' }}
				>
					This information will be stored as per our privacy policy and Terms of
					Use
				</Typography>
			</Box>
		</AuthLayout>
	);
};

export default SignIn;
