import {
  Card,
  CardContent,
  CardMedia,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Navi from "../../component/Navi";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles(() => ({
  cardBox: {
    width: "50%",
    margin: "0 auto",
    marginTop: 30,
  },
  cardDetail: { display: "flex", flexDirection: "row" },
  cancel: { fontSize: 80, textAlign: "center" },
}));

const Igbo = () => {
  const classes = useStyles();
  return (
    <section id="contact" className="contact-section">
      <Navi navType="dark" />
      <Container>
        <div className="section-header text-center">
          <Typography
            variant="h2"
            className="wow fadeInUp"
            data-wow-delay=".2s"
            color="error"
          >
            Transaction Failed
          </Typography>
        </div>

        <Card className={classes.cardBox}>
          <div className={classes.cardDetail}>
            <CardMedia>
              <CancelIcon color="error" className={classes.cancel} />
            </CardMedia>
            <CardContent>
              <Typography variant="body1">
                You canceled the payment or the payment processor couldn't
                process the transaction
              </Typography>
            </CardContent>
          </div>
        </Card>
      </Container>
    </section>
  );
};

export default Igbo;
