import React, { useContext, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { Appcontext } from '../context/AppProvider';
import Checkout from './Checkout';
import Navi from './Navi';

const Pricing = () => {
	const [state, dispatch] = useContext(Appcontext);
	const [isModal, setModal] = useState(false);
	const _getPdt = (item, cost) => {
		dispatch({ type: 'SET_PDT', pdt: { item, cost } });
		setModal(true);
	};
	const closeModal = () => {
		setModal(false);
	};
	return (
		<section id="pricing" className="pricing-section">
			<Navi navType="dark" />
			<Checkout open={isModal} _close={closeModal} />
			<Container>
				<div className="section-header text-center">
					<h2 className="wow fadeInUp" data-wow-delay=".2s">
						Our Bestsellers
					</h2>
					<p className="wow fadeInUp" data-wow-delay=".4s">
						Limited stocks available
					</p>
					<p>{`${state.pdt?.item} Selected`}</p>
				</div>

				<div className="row">
					<div className="col-md-3">
						<div className="p-table wow fadeInRight" data-wow-delay=".8s">
							<div className="header">
								<h4>Branding</h4>
								<div className="price">
									<span className="currency">$</span>
									<span className="amount">49</span>
									<span className="period">/month</span>
								</div>
							</div>
							<ul className="items">
								<li>Complimentary Cards</li>
								<li>Logo</li>
								<li>Letter Head</li>
								<li>Simple Website</li>
							</ul>
							<Button
								color="link"
								onClick={() => _getPdt('BRANDING', 4900)}
								className="pricing-btn arrow-btn"
							>
								Choose This
								<span className="arrow"></span>
							</Button>
						</div>
					</div>
					<div className="col-md-3">
						<div className="p-table standard wow bounceIn" data-wow-delay=".2s">
							<div className="header">
								<h4>Training</h4>
								<div className="price">
									<span className="currency">$</span>
									<span className="amount">99</span>
									<span className="period">/month</span>
								</div>
							</div>
							<ul className="items">
								<li>Android</li>
								<li>iOS</li>
								<li>Web</li>
							</ul>
							<Button
								color="link"
								onClick={() => _getPdt('TRAINING', 9900)}
								className="pricing-btn arrow-btn"
							>
								Choose This
								<span className="arrow"></span>
							</Button>
						</div>
					</div>
					<div className="col-md-3">
						<div className="p-table standard wow bounceIn" data-wow-delay=".2s">
							<div className="header">
								<h4>Ecommerce</h4>
								<div className="price">
									<span className="period">Starting from</span>
									<span className="currency">$</span>
									<span className="amount">49</span>
									<span className="period">/month</span>
								</div>
							</div>
							<ul className="items">
								<li>Shopify</li>
								<li>Zencart</li>
								<li>Woocommerce</li>
								<li>Opencart</li>
								<li>Magento</li>
								<li>PrestaShop</li>
								<li>Drupal Commerce</li>
								<li>Custom Store</li>
							</ul>
							<Button
								color="link"
								onClick={() => _getPdt('ECOMMERCE', 4900)}
								className="pricing-btn arrow-btn"
							>
								Choose This
								<span className="arrow"></span>
							</Button>
						</div>
					</div>

					<div className="col-md-3">
						<div className="p-table wow fadeInLeft" data-wow-delay=".8s">
							<div className="header">
								<h4>Software Development</h4>
								<div className="price">
									<span className="currency">$</span>
									<span className="amount">50</span>
									<span className="period">/hr</span>
								</div>
							</div>
							<ul className="items">
								<li>Java</li>
								<li>Node JS</li>
								<li>Angular</li>
								<li>React</li>
							</ul>
							<Button
								color="link"
								onClick={() => _getPdt('SOFTWARE DEVELOPMENT', 5000)}
								className="pricing-btn arrow-btn"
							>
								Choose This
								<span className="arrow"></span>
							</Button>
						</div>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default Pricing;
