import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
  return (
    <footer>
      {/* <footer className="fixed-bottom"> */}
      <Container>
        <Row>
          <Col>
            <p className="wow fadeInLeft" data-wow-delay=".2s">
              &copy; {new Date().getFullYear()} myeverlasting.net by ESSL. All
              Rights Reserved.
            </p>
          </Col>

          <Col>
            <ul className="footer-social wow fadeInRight" data-wow-delay=".2s">
              <li>
                <a href="https://facebook.com/esslNg">
                  <i className="ion-social-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/esslng">
                  <i className="ion-social-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="ion-social-pinterest"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="ion-social-dribbble"></i>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
