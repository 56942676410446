import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import wow from 'wow.js';
import { esslContact } from '../api/esslApi';
import Navi from './Navi';
const findLoc = async () => {
	const response = await fetch(`https://extreme-ip-lookup.com/json/`);
	if (!response.ok) {
		return { error: { code: response.status } };
	}
	const data = await response.json();
	return { data };
};
const Contact = () => {
	useEffect(() => {
		const wo = new wow();
		wo.init();
		placeholder();
	}, []);
	const [juser, setUser] = useState({});
	const [info, setInfo] = useState(null);
	const [loading, setLoading] = useState(false);
	const placeholder = () => {
		setInfo(null);
		setLoading(false);
	};
	const _change = (e) => {
		const { value, id } = e.target;
		const newUser = { ...juser };
		newUser[id] = value;
		setUser(newUser);
	};

	const _submit = async () => {
		setLoading(true);
		const { email, fullname, title, body } = juser;
		const pattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
		if (email === '' || email === null) {
			setInfo({ type: 'warning', msg: 'Email is required!' });
			setLoading(false);
			return;
		}
		if (!pattern.test(email)) {
			setInfo({ type: 'error', msg: 'Email is invalid!' });
			setLoading(false);
			return;
		}
		const loc = await findLoc();
		const auser = {
			projectowner: email,
			email,
			location: JSON.stringify(loc?.data),
			ip: loc?.data?.ipName,
			title,
			project: title,
			message: body,
			name: fullname || 'Horace User ',
			body: { ...juser, ...loc?.data },
		};
		if ('projectowner' in auser) {
			await esslContact(auser);
			setInfo({
				type: 'success',
				msg:
					'Thank you for contacting ESSL, a member of staff will contact you shortly!',
			});
			setLoading(false);
			setUser({});
			return;
		}
	};
	const closeToast = () => setInfo(null);
	return (
		<section id="contact" className="contact-section">
			<Helmet>
				<title>ESSL - Contact</title>
				<meta
					name="description"
					content="Contact for Everlasting Systems and Solutions LLC"
				></meta>
				<meta
					name="keywords"
					content="web, mobile, applications, software development, contact ESSL"
				/>
			</Helmet>
			<Navi navType="dark" />
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<div className="section-header text-center">
						<h2 className="wow fadeInUp" data-wow-delay=".2s">
							Hello {juser?.fullname}!
						</h2>
						<p className="wow fadeInUp" data-wow-delay=".4s">
							If you've got any questions, mail us, we will get in touch
						</p>
					</div>
				</Grid>
				<Grid item xs={2}></Grid>
				<Grid item xs={8}>
					<>
						{info && (
							<Alert
								severity={info?.type}
								onClose={closeToast}
								className="alertmg"
							>
								{info?.msg}
							</Alert>
						)}
						<form
							id="contact-form"
							name="contactForm"
							className="contact-form"
							data-toggle="validator"
						>
							<div id="contact-form-result"></div>

							<div className="row">
								<div className="col-md-6">
									<div className="form-group">
										<input
											type="text"
											className="form-control wow fadeInUp"
											data-wow-delay=".4s"
											name="name"
											id="fullname"
											placeholder="Fullname"
											value={juser?.fullname || ''}
											onChange={_change}
											required
										/>
										<div className="help-block with-errors"></div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
										<input
											type="email"
											className="form-control wow fadeInUp"
											data-wow-delay=".5s"
											name="email"
											id="email"
											placeholder="Email"
											value={juser?.email || ''}
											onChange={_change}
											required
										/>
										<div className="help-block with-errors"></div>
									</div>
								</div>
							</div>

							<div className="form-group">
								<input
									type="text"
									className="form-control wow fadeInUp"
									data-wow-delay=".6s"
									name="subject"
									id="title"
									placeholder="Subject"
									value={juser?.title || ''}
									onChange={_change}
									required
								/>
								<div className="help-block with-errors"></div>
							</div>

							<div className="form-group">
								<textarea
									className="form-control wow fadeInUp"
									data-wow-delay=".8s"
									name="message"
									id="body"
									placeholder="Message"
									rows="5"
									value={juser?.body || ''}
									onChange={_change}
								></textarea>
								<div className="help-block with-errors"></div>
							</div>

							<div className="form-group text-center">
								<button
									type="submit"
									className="btn-custom wow fadeInUp"
									data-wow-delay="1s"
									onClick={_submit}
									disabled={loading}
								>
									Send Message
									{loading && (
										<img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
									)}
								</button>
							</div>
						</form>
					</>
				</Grid>
				<Grid item xs={2}></Grid>
			</Grid>
		</section>
	);
};

export default Contact;
