import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Chip,
	Grid,
	Hidden,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { NavHashLink } from 'react-router-hash-link';

import React from 'react';
const useStyles = makeStyles(() => ({
	cardW: {
		marginTop: 30,
		marginBottom: 30,
	},
	card: {
		display: 'flex',
		minHeight: 225,
	},
	cardDetails: {
		flex: 1,
	},
	cardMedia: {
		width: 160,
	},

	flexBadge: {
		margin: '5px 10px',
	},
}));
const FeaturedPost = (props) => {
	const classes = useStyles();
	const { post } = props;

	return (
		<Grid item md={6} className={classes.cardW}>
			<CardActionArea component={NavHashLink} to={`/blog/${post.id}`}>
				<Card className={classes.card}>
					<div className={classes.cardDetails}>
						<CardContent>
							<Typography component="h2" variant="h5">
								{post?.title}
							</Typography>
							<Typography variant="subtitle1" color="textSecondary">
								{post?.date}
							</Typography>
							<Typography variant="subtitle1" paragraph>
								{post?.brief}
							</Typography>
							<Typography variant="subtitle1" color="primary">
								read more...
							</Typography>
							<Box className={classes.badgeContainer}>
								{post.category &&
									post.category.map((cat, index) => (
										<Chip
											key={index}
											variant="outlined"
											size="small"
											label={cat}
											color="secondary"
											className={classes.flexBadge}
										/>
									))}
							</Box>
						</CardContent>
					</div>
					<Hidden xsDown>
						<CardMedia
							className={classes.cardMedia}
							image={
								post?.thumb
									? `/img/${post.thumb}`
									: `https://via.placeholder.com/50x60?text=No-Image`
							}
							title={post?.imgText}
						/>
					</Hidden>
				</Card>
			</CardActionArea>
		</Grid>
	);
};

export default FeaturedPost;
