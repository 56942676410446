import React, { useContext, useState } from "react";
import { Button, makeStyles, Modal, TextField } from "@material-ui/core";

import { handlePay } from "../api/esslApi";
import { Appcontext } from "../context/AppProvider";
import { Link } from "react-router-dom";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: "center",
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
const doPay = (itm, amt) => {
  handlePay({ description: itm, amt });
};
const Checkout = (props) => {
  const classes = useStyles();
  const [state, dispatch] = useContext(Appcontext);
  const { open, _close } = props;
  const { pdt } = state || {};

  const [modalStyle] = useState(getModalStyle);
  const custPrice = (e) => {
    dispatch({
      type: "SET_PDT",
      pdt: { item: pdt.item, cost: e.target.value },
    });
  };

  return (
    <Modal open={open} onClose={_close} aria-labelledby="checkout modal">
      <div style={modalStyle} className={classes.paper}>
        <p>You have selected {pdt?.item} </p>

        <p className="amount">You are paying ${Math.ceil(+pdt?.cost / 100)}</p>

        {pdt?.item === "SOFTWARE DEVELOPMENT" && (
          <div>
            <p>or</p>
            <TextField
              label="Custom Amount (cents)"
              id="custAmt"
              onChange={custPrice}
              value={pdt?.cost}
            />
          </div>
        )}
        <p></p>
        <Button onClick={() => doPay(pdt?.item, pdt?.cost)} variant="contained">
          Confirm & Pay
        </Button>
        <p>or</p>
        <Link to="/contact" className="linkText">
          contact us for more details
        </Link>
      </div>
    </Modal>
  );
};

export default Checkout;
