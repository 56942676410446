import React from 'react';
import { Container, CssBaseline, Grid, makeStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import FeaturedPost from '../component/blog/FeaturedPost';
import { posts } from '../component/blog/posts';
import Navi from '../component/Navi';
const useStyles = makeStyles((theme) => ({
	mainGrid: {
		marginTop: theme.spacing(3),
	},
}));
const Blogs = () => {
	const classes = useStyles();
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<Helmet>
				<title>ESSL - Blog</title>
				<meta
					name="description"
					content="Blog for Everlasting Systems and Solutions LLC"
				></meta>
				<meta
					name="keywords"
					content="blog,web, mobile, applications, software development, ESSL"
				/>
			</Helmet>
			<CssBaseline />
			<Navi navType="dark" />
			<Container maxWidth="lg">
				<main
					style={{
						minHeight: '60vh',
					}}
					id="intro"
					className="mt-5 main-section-nobg parallax"
				>
					<Grid container spacing={4} className={classes.mainGrid}>
						{posts.map((itm, idx) => (
							<FeaturedPost key={idx} post={itm} />
						))}
					</Grid>
				</main>
			</Container>
		</>
	);
};

export default Blogs;
