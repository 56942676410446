import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import FeaturedPost from './FeaturedPost';
import { posts } from './posts';
const useStyles = makeStyles((theme) => ({
	mainGrid: {
		marginTop: theme.spacing(3),
	},
}));
const Blogsection = () => {
	const classes = useStyles();
	return (
		<section id="solutions" className="features-2-section">
			<div className="container">
				<div className="section-header text-center">
					<h2 className="wow fadeInUp" data-wow-delay=".2s">
						BLOG
					</h2>
					<p className="wow fadeInUp" data-wow-delay=".4s">
						Latest events and articles
					</p>
				</div>
				<main>
					<Grid container spacing={4} className={classes.mainGrid}>
						{posts.map((itm, idx) => (
							<FeaturedPost key={idx} post={itm} />
						))}
					</Grid>

					<div className="mt-5 btns text-center">
						<Link
							to={'/blogs'}
							className="form-control btn-custom wow fadeInUp w-100 my-3 badge-color"
							data-wow-delay="1s"
						>
							View More Blogs
						</Link>
					</div>
				</main>
			</div>
		</section>
	);
};

export default Blogsection;
